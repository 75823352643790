<template lang="">
    <div class="bio-container">
       <!-- <img :src="require('../assets/main-bio.png' )" :alt="window.altText" />  -->
       
     hello
    </div>
</template>
<script>
export default {
    name:'Bio'
}
</script>
<style lang="">
  
</style>